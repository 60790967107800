var __ = {},
	exports = {__};

__.oDefaults = {
	'registeredDataLayerObjects': ['page', 'teaser', 'user'],
	'registeredAnalyticsObjects': ['event', 'page', 'teaser']
};

/**
 * get webAnalytics container or create new one
 * @returns {Array} web analytics container
 */
__.getWebAnalyticsContainer = function() {
	if (typeof window.webAnalytics === 'undefined') {
		window.webAnalytics = [];
	}

	return window.webAnalytics;
};

/**
 * init data layer object
 */
exports.initDataObject = function() {
	window.digitalData = {};
	__.initialTrackingDone = true;
};

/**
 * wrapper for data object access
 * @returns {Object} the window.digitalData object
 */
__.getDataObject = function() {
	return window.digitalData;
};

/**
 * check, whether anything has already been written into window.digitalData
 * @returns {boolean} true in case there is already data, false else
 */
exports.isInitialTrackingDone = function() {

	if (!!__.initialTrackingDone) {
		return __.initialTrackingDone;
	}

	return false;
};

/**
 * store registered objects from combined object in data layer
 * @param {Object} combinedObject - combined tracking object
 * @param {Array} excludeObjects - array of objects to exclude from Datalayer
 */
__.storeRegisteredInDataObject = function(combinedObject, excludeObjects) {
	var i, len, currentObjectKey;

	for (i = 0, len = __.oDefaults.registeredDataLayerObjects.length; i < len; i++) {
		currentObjectKey = __.oDefaults.registeredDataLayerObjects[i];

		if (Object.prototype.hasOwnProperty.call(combinedObject, currentObjectKey) && excludeObjects.indexOf(currentObjectKey) === -1) {
			__.getDataObject()[currentObjectKey] = combinedObject[currentObjectKey];

			if (currentObjectKey === 'page' && !!__.cachedObjects && __.cachedObjects.length > 0) {

				while (__.cachedObjects.length > 0) {
					__.sendOnCachedObject(__.cachedObjects.shift());
				}
			}
		}
	}
};

/**
 * check whether page object is contained in data layer
 * @returns {boolean} - true if page object is contained, false else
 */
__.isPageObjectInDataLayer = function() {
	return __.isPageObjectInTrackingObject(__.getDataObject());
};

/**
 * check whether page object is contained in data object
 * @param {Object} dataObject - the data object to check
 * @returns {boolean} - true if page object is contained, false else
 */
__.isPageObjectInTrackingObject = function(dataObject) {
	if (!!dataObject && !!dataObject.page) {
		return true;
	}

	return false;
};

/**
 * cache a tracking object locally
 * @param {Object} trackingObject - the tracking object to cache
 */
__.cacheTrackingObject = function(trackingObject) {
	if (typeof __.cachedObjects === 'undefined') {
		__.cachedObjects = [];
	}

	__.cachedObjects.push(trackingObject);
};

/**
 * send on a cached object
 * @param {Object} combinedObject - the object to push
 */
__.sendOnCachedObject = function(combinedObject) {
	var analyticsContainer = __.getWebAnalyticsContainer();

	analyticsContainer.push(combinedObject);
};

/**
 * push combined tracking data to interface
 * @param {Object} combinedObject - the complete tracking object to push
 */
exports.sendData = function(combinedObject) {
	var analyticsContainer = __.getWebAnalyticsContainer();
	var i, currentObjectKey, analyticsObject = {};

	__.storeRegisteredInDataObject(combinedObject, []);

	// push only registered objects to analytics
	for (i = 0; i < __.oDefaults.registeredAnalyticsObjects.length; i++) {
		currentObjectKey = __.oDefaults.registeredAnalyticsObjects[i];

		if (Object.prototype.hasOwnProperty.call(combinedObject, currentObjectKey)) {
			analyticsObject[currentObjectKey] = combinedObject[currentObjectKey];
		}
	}

	if (__.isPageObjectInDataLayer() === true || __.isPageObjectInTrackingObject(combinedObject)) {
		analyticsContainer.push(analyticsObject);
	}
	else {
		__.cacheTrackingObject(analyticsObject);
	}
};

/**
 * push combined tracking data to interface
 * @param {Object} combinedObject - the complete tracking object to push
 * @param {Array} excludeObjects - array of objects to exclude from Datalayer
 */
exports.sendDataWithCustomDatalayerExclusion = function(combinedObject, excludeObjects) {
	var analyticsContainer = __.getWebAnalyticsContainer();
	var i, currentObjectKey, analyticsObject = {};

	__.storeRegisteredInDataObject(combinedObject, excludeObjects);

	// push only registered objects to analytics
	for (i = 0; i < __.oDefaults.registeredAnalyticsObjects.length; i++) {
		currentObjectKey = __.oDefaults.registeredAnalyticsObjects[i];

		if (Object.prototype.hasOwnProperty.call(combinedObject, currentObjectKey)) {
			analyticsObject[currentObjectKey] = combinedObject[currentObjectKey];
		}
	}

	if (__.isPageObjectInDataLayer() === true || __.isPageObjectInTrackingObject(combinedObject)) {
		analyticsContainer.push(analyticsObject);
	}
	else {
		__.cacheTrackingObject(analyticsObject);
	}
};

export {exports as dtmApi};
