var __ = {},
	exports = {__};

/**
 * returns the current user object
 * @returns {Object} - the current user object
 */
__.getUserObject = function() {
	return {
		segment: {
			loginStatus: 0 // hard-coded since there is no login facility -> unified tracking over all applications
		}
	};
};

/**
 * get page object
 * @param {Object} dataObject - the the base tracking object
 * @returns {Object} the page object
 */
exports.getData = function(dataObject) {
	return new Promise(resolve => {
		var returnObject = dataObject;

		returnObject.user = dataObject.user ? dataObject.user : [];
		returnObject.user.push(__.getUserObject());
		resolve(returnObject);
	});
};

export {exports as userObject};
