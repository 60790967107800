import {dom} from 'core-utils';
import {dtmApi} from '../apis/dtm-api';
import {eventObject} from '../objects/event-object';
import {teaserObject} from '../objects/teaser-object';

var __ = {},
	exports = {__};

/**
 * add events
 */
__.addEvents = function() {
	__.domDelegateEventBus.off('click', 'a', __.handlerTrackLink);
	__.domDelegateEventBus.on('click', 'a', __.handlerTrackLink);
};

/**
 * extract event name from domElement
 * @param {Element} domElement - the DOM element to retrieve the name from
 * @returns {string} - the event name
 */
__.getEventName = function(domElement) {
	var eventName = '';

	if (domElement !== null) {
		eventName = domElement.innerText;

		if (eventName === '' && domElement.getAttribute('title')) {
			eventName = domElement.getAttribute('title');
		}
	}

	return eventName;
};

/**
 * extract event type from domElment
 * @param {Element} domElement - the DOM element to retrieve the type from
 * @returns {string} - the event type (module name ot the containing module)
 */
__.getEventType = function(domElement) {
	var eventType = '';
	var element = domElement;

	if (element !== null) {
		while (element.parentNode) {
			element = element.parentNode;

			if (element && element.nodeType === 1 && element.hasAttribute('data-module') === true) {
				eventType = element.getAttribute('data-module');
			}

			if (eventType && eventType !== '') {
				break;
			}
		}
	}

	return eventType;
};

/**
 * extract event attributes from domElement
 * @param {Element} domElement - the DOM element to retrieve the type from
 * @returns {Object} - the element attributes within one object
 */
__.getEventAttributes = function(domElement) {
	var eventAttributes = {};
	var href = '';

	if (domElement !== null) {
		href = domElement.getAttribute('href');

		if (href) {
			eventAttributes.href = href;
		}
	}

	return eventAttributes;
};

/**
 * check whether link is download link
 * @param {Element} domElement - the link element to check
 * @returns {boolean} true if it is a download link, false else
 */
__.getDownloadLink = function(domElement) {
	var toReturn = false;

	if (domElement !== null) {
		if (domElement.getAttribute('download') !== null) {
			toReturn = domElement.getAttribute('download');
		}
		else if (__.isDownloadURL(__.getLinkHref(domElement))) {
			toReturn = __.getLinkHref(domElement);
		}
	}

	return toReturn;
};

/**
 * get href attribute of link
 * @param {Element} domElement - the link element to check
 * @returns {string} the href attribute
 */
__.getLinkHref = function(domElement) {
	var href = '';

	if (domElement !== null) {
		href = domElement.getAttribute('href');
	}

	return href;
};

/**
 * check whether the href of this link has a pdf or zip ending
 * @param {string} href - the string to check
 * @returns {boolean} true, in case it has a matching ending, false else
 */
__.isDownloadURL = function(href) {
	var toReturn = false;
	var fileEnding;
	var filenameParts = (href||'').split('.');

	if (filenameParts && filenameParts[filenameParts.length-1]) {
		fileEnding = filenameParts[filenameParts.length-1].toUpperCase();

		if (fileEnding === 'ZIP' || fileEnding === 'PDF') {
			toReturn = true;
		}
	}

	return toReturn;
};

/**
 * get file type from file name
 * @param {string} downloadFilename - the file name
 * @returns {string} file type
 */
__.extractFileType = function(downloadFilename) {
	var filenameParts = downloadFilename.split('.');
	var fileEnding = filenameParts[filenameParts.length-1];

	return fileEnding.toUpperCase();
};

/**
 * get file name from a url
 * @param {Element} targetHyperlink - the hyperlink
 * @returns {string} file type
 */
__.extractFilename = function(targetHyperlink) {
	var href = (!!targetHyperlink && !!targetHyperlink.href) ? targetHyperlink.href : '';
	var pathParts = href.split('/');
	var fileNameWithEnding = pathParts[pathParts.length-1];
	var filenameParts = fileNameWithEnding.split('.');

	return filenameParts[filenameParts.length-2];
};

/**
 * check whether the href is an external link or not
 * @param {string} href - the href target
 * @returns {boolean} true, if external, false else
 */
__.isExternalDestination = function(href) {
	if (!!href && (href.indexOf('http') === 0 || href.indexOf('//') === 0)) {
		return true;
	}

	return false;
};

/**
 * get the domain name from an URL (everything from between // and the next /)
 * @param {string} href - the URL
 * @returns {string} the domain name
 */
__.getDomainFromURL = function(href) {
	var domain = '';
	var splitHref = href.split('/');

	if (__.isExternalDestination(href) && splitHref.length > 2) {
		domain = splitHref[2];
	}

	return domain;
};

/**
 * check, whether a domain name is an Audi domain
 * (i.e. audi occurrence in domain, not subdomain
 * or second level, in case first level is co or com)
 * @param {string} domain - the domain
 * @returns {boolean} - true, if it is an Audi domain, false else
 */
__.isAudiDomain = function(domain) {
	var domainParts = domain.split('.');
	var sld = domainParts[domainParts.length-2];
	var domainName;

	if (sld === 'co' || sld === 'com') {
		domainName = domainParts[domainParts.length-3];
	}
	else {
		domainName = domainParts[domainParts.length-2];
	}

	if (domainName.indexOf('audi') !== -1) {
		return true;
	}

	return false;
};

/**
 * check whether the href is an exit link
 * i.e. an external destination and not related to an Audi domain
 * @param {string} href - the href to check
 * @returns {boolean} true, in case it is an exit link, false else
 */
__.isExitLink = function(href) {
	var domain = __.getDomainFromURL(href);

	if (__.isExternalDestination(href) && !__.isAudiDomain(domain)) {
		return true;
	}

	return false;
};

__.attachTeaserTrackingEventIfApplicable = function(dataObj, targetHyperlink, event) {
	var teaserClickItem = dom.closest(event.target, '.nm-j-teaser-click-item');

	if (dom.isElement(teaserClickItem)) {
		return eventObject.getData(dataObj, 'teaser_click', '', 'teaser', {}).then((dataObject) => {
			return teaserObject.getClickedTeaserData(dataObject, targetHyperlink, teaserClickItem, event);
		});
	}
	else {
		return Promise.resolve(dataObj);
	}
};

/**
 * click handler which triggers the click tracking
 * @param {Event} event - the click event
 */ // eslint-disable jsdoc/require-returns
__.handlerTrackLink = function(event) {
	var targetHyperlink = dom.closest(event.target, 'a');
	var eventName = __.getEventName(targetHyperlink);
	var eventType = __.getEventType(targetHyperlink);
	var eventAttributes = __.getEventAttributes(targetHyperlink);
	var downloadLink = __.getDownloadLink(targetHyperlink);
	var href = targetHyperlink.getAttribute('href');

	return new Promise(resolve => {
		if (downloadLink) {
			if (__.isExitLink(href)) {
				eventObject.getData({}, 'click', eventName, eventType, eventAttributes)
					.then((dataObj) => {
						return __.attachTeaserTrackingEventIfApplicable(dataObj, targetHyperlink, event);
					})
					.then((dataObj) => {
						return eventObject.getData(dataObj, 'exit_link', eventName, eventType, eventAttributes);
					})
					.then((dataObj) => {
						return eventObject.getData(dataObj, __.extractFileType(downloadLink)+' download', downloadLink, 'download', {'url':__.getLinkHref(targetHyperlink)});
					})
					.then(dtmApi.sendData).then(resolve)
					.catch((err) => {
						console.error('click tracking error (download): ' + err);
					});
			}
			else {
				eventObject.getData({}, 'click', eventName, eventType, eventAttributes)
					.then((dataObj) => {
						return __.attachTeaserTrackingEventIfApplicable(dataObj, targetHyperlink, event);
					})
					.then((dataObj) => {
						return eventObject.getData(dataObj, __.extractFileType(downloadLink)+' download', downloadLink, 'download', {'url':__.getLinkHref(targetHyperlink)});
					})
					.then(dtmApi.sendData).then(resolve)
					.catch((err) => {
						console.error('click tracking error (download): ' + err);
					});
			}
		}
		else {
			if (__.isExitLink(href)) {
				eventObject.getData({}, 'click', eventName, eventType, eventAttributes)
					.then((dataObj) => {
						return __.attachTeaserTrackingEventIfApplicable(dataObj, targetHyperlink, event);
					})
					.then((dataObj) => {
						return eventObject.getData(dataObj, 'exit_link', eventName, eventType, eventAttributes);
					})
					.then(dtmApi.sendData).then(resolve)
					.catch((err) => {
						console.error('click tracking error: ' + err);
					});
			}
			else {
				eventObject.getData({}, 'click', eventName, eventType, eventAttributes)
					.then((dataObj) => {
						return __.attachTeaserTrackingEventIfApplicable(dataObj, targetHyperlink, event);
					})
					.then(dtmApi.sendData).then(resolve)
					.catch((err) => {
						console.error('click tracking error: ' + err);
					});
			}
		}
	});
};

/**
 * public initialization method
 * @returns {Promise} resolves if initialized
 */
exports.initialize = function() {
	return new Promise(resolve => {
		__.domDelegateEventBus = dom.getEventDelegate('body');
		__.addEvents();
		resolve('tracking/events/click-tracking.js');
	});
};

export {exports as clickTracking};
