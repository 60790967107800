import {appEvents} from 'application-bundle';
import {dtmApi} from '../apis/dtm-api';
import {eventObject} from '../objects/event-object';
import {pageObject} from '../objects/page-object';
import {teaserObject} from '../objects/teaser-object';
import {userObject} from '../objects/user-object';

var __ = {},
	exports = {__};

/**
 * add events
 */
__.addEvents = function() {
	__.eventBus.on(appEvents.LAYER_LOADED, __.handlerLayerLoaded);
};

/**
 * handler for LAYER_LOADED event
 */
__.handlerLayerLoaded = function() {

	// normal layer tracking, in case we do not have a direct layer call
	if (dtmApi.isInitialTrackingDone) {
		// default layer tracking
		eventObject.getData({}, 'layer_show', '', 'view', {})
			.then(pageObject.getData)
			.then(teaserObject.getData)
			.then(userObject.getData)
			.then(dtmApi.sendData)
			.catch((err) => {
				console.error('layer-tracking error: ' + err);
			});
	}
	// do a page_load tracking for a direct layer call (but only if there is not also a
	// headless page in the URL - in such a case the page_load tracking will be covered
	// by the PAGE_READY listener of the page_tracking
	else if (location.hash.indexOf('layer=') !== -1 && location.hash.indexOf('page=') === -1) {
		eventObject.getData({}, 'page_load', '', 'view', {})
			.then(pageObject.getData)
			.then(teaserObject.getData)
			.then(userObject.getData)
			.then(dtmApi.sendData)
			.catch((err) => {
				console.error('layer-tracking error (direct entry): ' + err);
			});
	}
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - tbd
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.eventBus = globalEventBus;
		__.addEvents();
		resolve('tracking/events/layer-tracking.js');
	});
};

export {exports as layerTracking};
