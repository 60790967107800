(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("application-bundle"), require("core-utils"));
	else if(typeof define === 'function' && define.amd)
		define("tracking-bundle", ["application-bundle", "core-utils"], factory);
	else if(typeof exports === 'object')
		exports["tracking-bundle"] = factory(require("application-bundle"), require("core-utils"));
	else
		root["tracking-bundle"] = factory(root["application-bundle"], root["core-utils"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__5001__, __WEBPACK_EXTERNAL_MODULE__1139__) {
return 