import {appEvents} from 'application-bundle';
import {dom} from 'core-utils';
import {dtmApi} from '../apis/dtm-api';
import {eventObject} from '../objects/event-object';

var __ = {},
	exports = {__};

/**
 * initialize
 */
__.initialize = function() {
	__.lastPosition = 0.0;
	__.addEvents();
};

/**
 * add events
 */
__.addEvents = function() {
	__.playedOnceInLoop = false;
	__.handleVideoListeners();
	__.eventBus.on(appEvents.LAYER_LOADED, __.handleVideoListeners);
};

/**
 * get context of loaded DOM
 * @param {Object|HTMLElement} data_ - the event's context payload
 * @returns {HTMLElement} - DOM Fragment of the context
 */
__.getContext = function(data_) {
	var contextElement;

	if (!!data_ && !data_.context) {
		contextElement = (!!data_.domElement ? data_.domElement[0] : data_.element);
	}
	else {
		contextElement = document;
	}

	return contextElement;
};

__.removeVideoEventListener = function(video) {
	var noTrackingAttribute = video.hasAttribute('data-video-notracking');

	if (noTrackingAttribute === false) {
		try {
			video.removeEventListener('playing', __.handleVideoPlay);
			video.removeEventListener('pause', __.handleVideoPause);
			video.removeEventListener('ended', __.handleVideoEnded);
			video.removeEventListener('timeupdate', __.handleVideoTimeupdate);
		}
		catch (err) {
			console.error('video-tracking error (add event listener): ' + err);
		}
	}
};

__.addVideoEventListener = function(video) {
	var noTrackingAttribute = video.hasAttribute('data-video-notracking');

	if (noTrackingAttribute === false) {
		try {
			video.addEventListener('playing', __.handleVideoPlay);
			video.addEventListener('pause', __.handleVideoPause);
			video.addEventListener('ended', __.handleVideoEnded);
			video.addEventListener('timeupdate', __.handleVideoTimeupdate);
		}
		catch (err) {
			console.error('video-tracking error (add event listener): ' + err);
		}
	}
};

__.handleVideoListeners = function(data_) {
	var context = __.getContext(data_),
		oldVideoElements = dom.getElementsArray('video'),
		newVideoElements = dom.getElementsArray('video', context),
		i;

	for (i=0; i<oldVideoElements.length; i++) {
		__.removeVideoEventListener(oldVideoElements[i]);
	}

	for (i=0; i<newVideoElements.length; i++) {
		__.addVideoEventListener(newVideoElements[i]);
	}
};

/**
 * track a parametrized video action
 * @param {HTMLElement} video - the video element
 * @param {string} action - the video action
 * @param {Object} attributes - the video attributes
 */
__.trackGenericVideoAction = function(video, action, attributes) {
	var videoFilename;

	if (!!video.currentSrc) {
		videoFilename = video.currentSrc.split('/').slice(-1)[0];
		eventObject.getData({}, 'video_'+action, videoFilename, 'video', attributes)
			.then(dtmApi.sendData)
			.catch((err) => {
				console.error('video-tracking error (' + action + '): ' + err);
			});
	}
};

__.handleVideoPlay = function() {
	// special case for videos with real loop attribute - they do not provide an ended event
	// thus have to be treated to stop tracking after first complete view
	var remaining = this.duration;

	if (__.lastPosition !== 0.0) {
		remaining = this.duration - __.lastPosition;
	}

	if (!!this.loop && remaining !== this.duration && remaining <= 1.0) {
		__.playedOnceInLoop = true;
	}

	if (__.playedOnceInLoop === false) {
		__.trackGenericVideoAction(this, 'play', {'url': this.currentSrc});
	}
};

__.handleVideoPause = function() {
	if (__.playedOnceInLoop === false) {
		__.trackGenericVideoAction(this, 'pause', {'url': this.currentSrc});
	}
};

__.handleVideoEnded = function() {
	var autoplayAttribute = this.parentNode.getAttribute('data-autoplay');

	if (__.playedOnceInLoop === false) {
		__.trackGenericVideoAction(this, 'end', {'url': this.currentSrc});
	}

	if (!!this.loop && this.loop === true ||
		!!this.autoplay && this.autoplay === true ||
		autoplayAttribute === true || autoplayAttribute === 'true') {
		__.playedOnceInLoop = true;
	}
};

__.handleVideoTimeupdate = function() {
	var timeDelta, percentage;

	if (__.playedOnceInLoop === false) {
		if (!!this.currentTime && !!this.duration && !!this.currentSrc) {
			timeDelta = Math.abs(__.lastPosition - this.currentTime);

			if (timeDelta >= 1) {
				__.lastPosition = this.currentTime;
				percentage = Math.floor(__.lastPosition / this.duration * 100.0);
				__.trackGenericVideoAction(this, 'progress', {'url': this.currentSrc, 'duration': percentage});
			}
		}
	}
};

exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.eventBus = globalEventBus;
		__.initialize();
		resolve('tracking/events/video-tracking.js');
	});
};

export {exports as videoTracking};
