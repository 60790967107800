import {dtmApi} from '../apis/dtm-api';
import {eventObject} from '../objects/event-object';
import {pageObject} from '../objects/page-object';
import {userObject} from '../objects/user-object';
import {teaserObject} from '../objects/teaser-object';

var __ = {},
	exports = {__};

/**
 * add events
 */
__.addEvents = function() {
	__.handlerAppInitialized();
};

/**
 * handler for APP_INITIALIZED event
 */
__.handlerAppInitialized = function() {
	dtmApi.initDataObject();

	// do not track here if we have a direct entry into a headless page or a layer
	if (location.hash.indexOf('page=') === -1 && location.hash.indexOf('layer=') === -1) {
		__.performDefaultPageTracking();
	}
};

/**
 * perform a default page tracking
 */
__.performDefaultPageTracking = function() {
	eventObject.getData({}, 'page_load', '', 'view', {})
		.then(pageObject.getData)
		.then(teaserObject.getData)
		.then(userObject.getData)
		.then(dtmApi.sendData)
		.catch((err) => {
			console.error('page-tracking error: ' + err);
		});
};

/**
 * public initialize method
 * @param {EventBus} globalEventBus - tbd
 * @returns {Promise} returns Promise
 */
exports.initialize = function(globalEventBus) {
	return new Promise(resolve => {
		__.eventBus = globalEventBus;
		__.addEvents();
		resolve('tracking/events/page-tracking.js');
	});
};

export {exports as pageTracking};
