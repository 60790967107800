import {dom} from 'core-utils';

var __ = {},
	exports = {__};

/**
 * get names of visible teaser modules on page
 * @param {Element} context - the DOM context to look for teasers
 * @returns {Array} array with teaser module names
 */
__.getNamesOfVisibleTeaserModules = function(context) {
	var i, teaserModule;
	var occurringTeaserModules = [];
	var allTeaserModules = dom.getElementsArray('.nm-j-teaser-module', context);

	for (i=0; i<allTeaserModules.length; i++) {

		// only visible teasers
		if (allTeaserModules[i].offsetParent !== null) {
			teaserModule = allTeaserModules[i].getAttribute('data-module');

			if (occurringTeaserModules.indexOf(teaserModule) === -1) {
				occurringTeaserModules.push(teaserModule);
			}
		}
	}

	return occurringTeaserModules;
};

/**
 * collect the teaser object array from DOM
 * @param {Element} context - the DOM context to look for teasers
 * @returns {Array} teaser object array
 */
__.getTeaserObjectArray = function(context) {
	var i, j, k, teaserModulesOfAKind, teaserItemsWithinModule, teaserObject;
	var teaserObjectArray = [];
	var occurringTeaserModules = __.getNamesOfVisibleTeaserModules(context);

	for (i=0; i<occurringTeaserModules.length; i++) {
		teaserModulesOfAKind = dom.getElementsArray('.nm-j-teaser-module[data-module="'+occurringTeaserModules[i]+'"]', context);

		for (j=0; j<teaserModulesOfAKind.length; j++) {

			if (__.containsTeaserItems(teaserModulesOfAKind[j])) {
				teaserItemsWithinModule = dom.getElementsArray('.nm-j-teaser-item', teaserModulesOfAKind[j]);

				for (k=0; k<teaserItemsWithinModule.length; k++) {
					teaserObject = __.getTeaserItem(teaserItemsWithinModule[k], occurringTeaserModules[i], j, k);
					teaserObjectArray.push(teaserObject);
				}
			}
			else {
				teaserObject = __.getTeaserItem(teaserModulesOfAKind[j], occurringTeaserModules[i], j, 0);
				teaserObjectArray.push(teaserObject);
			}
		}
	}

	return teaserObjectArray;
};

/**
 * get the teaser id
 * @param {Element} element - the dom element
 * @param {string} moduleName - the module name
 * @param {number} moduleNumber - the module number in order of occurrence on page
 * @param {number} teaserNumber - the teaser number in order of occurrence within twaser module
 * @returns {string} the teaser id
 */
__.getTeaserID = function(element, moduleName, moduleNumber, teaserNumber) {
	return moduleName + '-' + moduleNumber + '-' + teaserNumber + '-' + __.getTeaserTitle(element);
};

/**
 * get the teaser title
 * @param {Element} element - the dom element
 * @returns {string} the teaser title
 */
__.getTeaserTitle = function(element) {
	var parentTeaserModule;
	var teaserTitleElem = dom.getElement('.nm-j-teaser-title', element);

	// search upwards for e.g. case offer teaser
	if (!dom.isElement(teaserTitleElem)) {
		parentTeaserModule = dom.closest(element, '.nm-j-teaser-module');

		if (dom.isElement(parentTeaserModule)) {
			teaserTitleElem = dom.getElement('.nm-j-teaser-title', parentTeaserModule);
		}
	}

	if (dom.isElement(teaserTitleElem)) {
		return teaserTitleElem.innerText;
	}
	else {
		return 'untitled';
	}
};

/**
 * gets a teaser item in the needed object structure
 * @param {Object} element - the DPU item to create tracking object for
 * @param {string} moduleName - the module name
 * @param {number} moduleNumber - the number of the current module in the DOM
 * @param {number} teaserNumber - the number of the current teaser in the teaser module
 * @returns {Object} tracking object in the correct form
 */
__.getTeaserItem = function(element, moduleName, moduleNumber, teaserNumber) {

	var teaserObject = {
		'ID': __.getTeaserID(element, moduleName, moduleNumber, teaserNumber),
		'module': moduleName,
		moduleNumber,
		teaserNumber,
		'teaserTitle': __.getTeaserTitle(element)
	};

	return teaserObject;
};

/**
 * check whether a teaser module contains teaser items
 * @param {Element} teaserModuleElement - the teaser module element
 * @returns {boolean} whether the module contains teaser items {true} or not {false}
 */
__.containsTeaserItems = function(teaserModuleElement) {
	var teaserItem = dom.getElement('.nm-j-teaser-item', teaserModuleElement);

	return dom.isElement(teaserItem);
};

__.getTeaserObjectForItem = function(teaserModuleOfEnclosingKind, enclosingTeaserModule, j) {
	var k, teaserObject, enclosingTeaserItem, teaserItemsWithinModule;

	enclosingTeaserItem = dom.closest(event.target, '.nm-j-teaser-item');
	teaserItemsWithinModule = dom.getElementsArray('.nm-j-teaser-item', teaserModuleOfEnclosingKind);

	for (k=0; k<teaserItemsWithinModule.length; k++) {
		if (enclosingTeaserItem === teaserItemsWithinModule[k]) {
			teaserObject = __.getTeaserItem(teaserItemsWithinModule[k], enclosingTeaserModule, j, k);
			break;
		}
	}

	return teaserObject;
};

/**
 * get teaser item for click
 * @param {Element} targetHyperlink - the target hyperlink element
 * @param {Element} teaserClickItem - the clicked item element
 * @param {Event} event - the click event
 * @returns {Object} the teaser Object for this click
 */
__.getTeaserItemForClick = function(targetHyperlink, teaserClickItem, event) { // eslint-disable-line max-statements
	var enclosingTeaserModule, j, teaserModulesOfEnclosingKind, teaserObject, carlineTag, prString;
	var enclosingTeaser = dom.closest(event.target, '.nm-j-teaser-module');
	var trackCurrentCarline = teaserClickItem.getAttribute('data-tracking-carline');
	var trackPrString = teaserClickItem.getAttribute('data-tracking-prstring');

	if (dom.isElement(enclosingTeaser)) {
		enclosingTeaserModule = enclosingTeaser.getAttribute('data-module');
	}

	teaserModulesOfEnclosingKind = dom.getElementsArray('.nm-j-teaser-module[data-module="'+enclosingTeaserModule+'"]');

	for (j=0; j<teaserModulesOfEnclosingKind.length; j++) {

		if (enclosingTeaser === teaserModulesOfEnclosingKind[j]) {
			if (__.containsTeaserItems(enclosingTeaser)) {
				teaserObject = __.getTeaserObjectForItem(teaserModulesOfEnclosingKind[j], enclosingTeaserModule, j);
			}
			else {
				teaserObject = __.getTeaserItem(teaserModulesOfEnclosingKind[j], enclosingTeaserModule, j, 0);
			}
		}
	}

	teaserObject.clickedElement = event.target.innerText;
	teaserObject.clickedType = teaserClickItem.getAttribute('data-teaser-click-type');
	teaserObject.clickedHref = targetHyperlink.href;

	if (!!trackCurrentCarline && trackCurrentCarline !== '') {
		carlineTag = dom.getElement('meta[name=carline]', document);

		if (dom.isElement(carlineTag)) {
			teaserObject.carline = carlineTag.getAttribute('content');
		}
	}

	if (!!trackPrString && trackPrString !== '') {
		prString = teaserClickItem.getAttribute('data-prstring');

		if (!!prString && prString !== '') {
			teaserObject.prString = prString;
		}
	}

	return teaserObject;
};

/**
 * enrich data object with teaser object of clicked teaser
 * @param {Object} dataObj - the data object to enrich
 * @param {Element} targetHyperlink - the clicked hyperlink element
 * @param {Element} teaserClickItem - the clicked on teaser item (.nm-j-teaser-click-item)
 * @param {Event} event - the click event
 * @returns {Promise} - data object wrapped in promise resolve
 */
exports.getClickedTeaserData = function(dataObj, targetHyperlink, teaserClickItem, event) {
	dataObj.teaser = __.getTeaserItemForClick(targetHyperlink, teaserClickItem, event);
	return Promise.resolve(dataObj);
};

/**
 * get product object
 * @param {Object} dataObject - the the base tracking object
 * @param {Element} domElement - the DOM context to look for teasers
 * @returns {Object} the product object
 */
exports.getData = function(dataObject, domElement) {
	var context = (!!domElement && !!domElement.element) ? domElement.element : document;

	return new Promise(resolve => {
		dataObject.teaser = __.getTeaserObjectArray(context);
		resolve(dataObject);
	});
};

export {exports as teaserObject};
