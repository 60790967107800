var exports = {};

/**
 * build up the event object according to the spec
 * @param {Object} dataObject - the data object to add the event to
 * @param {string} eventAction - the event action (mandatory)
 * @param {string} eventName - the name of the event (may be empty)
 * @param {string} eventType - the event type
 * @param {Object} eventAttributes - additional event attributes (may be empty object)
 * @returns {Promise} - promise resolving with an event-enriched tracking object
 */
exports.getData = function(dataObject, eventAction, eventName, eventType, eventAttributes) { // eslint-disable-line max-params
	return new Promise(resolve => {
		var returnObject = dataObject;

		returnObject.event = dataObject.event ? dataObject.event : [];
		returnObject.event.push({
			eventInfo: {
				eventAction,
				eventName,
				type: eventType
			},
			attributes: eventAttributes
		});
		resolve(returnObject);
	});
};

exports.getTrackingConfigurationStartType = function(rawConfigurationStartType) {
	var trackingCongigurationStartType = 'new configuration';

	if (!!rawConfigurationStartType) {
		if (rawConfigurationStartType.indexOf('audicode') !== -1) {
			trackingCongigurationStartType = 'audicode';
		}
		else if (rawConfigurationStartType.indexOf('carstore') !== -1) {
			trackingCongigurationStartType = 'audicode';
		}
		else if (rawConfigurationStartType.indexOf('prstring') !== -1) {
			trackingCongigurationStartType = 'prstring';
		}
	}

	return trackingCongigurationStartType;
};

exports.getTrackingSource = function(rawConfigurationStartType) {
	var trackingSource = '';

	if (!!rawConfigurationStartType) {
		if (rawConfigurationStartType.indexOf('carstore') !== -1) {
			trackingSource = 'carstore';
		}
		else if (rawConfigurationStartType.indexOf('input') !== -1) {
			trackingSource = 'input form';
		}
		else if (rawConfigurationStartType.indexOf('link') !== -1) {
			trackingSource = 'link';
		}
		else if (rawConfigurationStartType.indexOf('user-configuration') !== -1) {
			trackingSource = 'local storage';
		}
		else if (rawConfigurationStartType.indexOf('configuration-change') !== -1) {
			trackingSource = 'change';
		}
		else if (rawConfigurationStartType.indexOf('configuration-reset') !== -1) {
			trackingSource = 'reset';
		}
		else if (rawConfigurationStartType.indexOf('teaser') !== -1) {
			trackingSource = 'teaser';
		}
		else if (rawConfigurationStartType.indexOf('search') !== -1) {
			trackingSource = 'onsite search';
		}
	}

	return trackingSource;
};

export {exports as eventObject};
