import {signal} from 'application-bundle';

import {clickTracking} from './tracking/events/click-tracking';
import {dtmApi} from './tracking/apis/dtm-api';
import {eventObject} from './tracking/objects/event-object';
import {layerTracking} from './tracking/events/layer-tracking';
import {pageObject} from './tracking/objects/page-object';
import {pageTracking} from './tracking/events/page-tracking';
import {teaserObject} from './tracking/objects/teaser-object';
import {userObject} from './tracking/objects/user-object';
import {videoTracking} from './tracking/events/video-tracking';

/**
 * modules to be initialized with event emitter
 */
const toBeInitialized = [
	clickTracking,
	layerTracking,
	pageTracking,
	videoTracking
];

/**
 * initialize modules and inject signal dependency
 */
const eventEmitter = signal.getEmitter();

toBeInitialized.forEach(module => {
	try {
		module.initialize(eventEmitter);
	}
	catch (err) {
		console.error(err);
	}
});

export {
	clickTracking,
	dtmApi,
	eventObject,
	layerTracking,
	pageObject,
	pageTracking,
	teaserObject,
	userObject,
	videoTracking
};
