var __ = {},
	exports = {__};

__.oDefaults = {
	'sStringProduction': 'production',
	'sStringStaging': 'staging',
	'sStringDevelopment': 'development',
	'sStringApplicationNameAXSPrefix': 'Mobility-'
};

/**
 * remove possible trailing ".html" from string
 * @param {string} calculatedPagename - the input string
 * @returns {string} calculatedPagename - string without trailing ".html"
 */
__.removeTrailingHtmlString = function(calculatedPagename) {
	if (calculatedPagename.indexOf('.html') > 0) {
		return calculatedPagename.substr(0, calculatedPagename.indexOf('.html'));
	}

	return calculatedPagename;
};

/**
 * get page id for tracking
 * @param {string} href_ - current href
 * @param {string} pathname_ - current pathname
 * @returns {string} page id
 */
__.getPageID = function(href_, pathname_) {
	var calculatedPagename = pathname_;
	var jslayer = false;

	if (href_ && href_.indexOf('jslayer=') > -1) {
		jslayer = href_.substr(href_.indexOf('jslayer=') + 8);

		if (href_ && href_.indexOf('page=') > -1) {
			calculatedPagename = href_.substr(href_.indexOf('page=') + 5);
		}
	}
	else if (href_ && href_.indexOf('layer=') > -1) {
		calculatedPagename = href_.substr(href_.indexOf('layer=') + 6);
	}
	else if (href_ && href_.indexOf('page=') > -1) {
		calculatedPagename = href_.substr(href_.indexOf('page=') + 5);
	}

	calculatedPagename = __.removeTrailingHtmlString(calculatedPagename);
	return jslayer ? calculatedPagename + '/' + jslayer : calculatedPagename;
};

/**
 * get Page name
 * @param {Element} domElement - the domElement to check for (optional)
 * @returns {string} - the page name
 */
__.getPageName = function(domElement) {
	var pageName = '';

	if (domElement && domElement.querySelectorAll('#nm-page-title') && domElement.querySelectorAll('#nm-page-title').length > 0) {
		pageName = domElement.querySelectorAll('#nm-page-title')[0].innerText;
	}
	else {
		pageName = document.getElementsByTagName('title')[0].innerText;
	}

	return pageName;
};

/**
 * get destination URL from location object
 * @returns {string} the destination URL
 */
__.getDestinationURL = function() {
	return window.location.href;
};

/**
 * get the referring URL
 * @returns {string} the referring URL
 */
__.getReferringURL = function() {
	return document.referrer;
};

/**
 * get sys env (production / staging)
 * @param {string} hostname - the current hostname
 * @returns {string} production by default, staging in case of hostname
 * contains "pre-" or "test-" or "local" or "axs" or "127.0.0.1"
 */
__.getSysEnv = function(hostname) {
	if (hostname.indexOf('local') !== -1 || hostname.indexOf('axs') !== -1 || hostname.indexOf('aem-t') !== -1 || hostname.indexOf('127.0.0.1') !== -1) {
		return __.oDefaults.sStringDevelopment;
	}
	else if (hostname.indexOf('pre-') !== -1 || hostname.indexOf('test-') !== -1) {
		return __.oDefaults.sStringStaging;
	}

	return __.oDefaults.sStringProduction;
};

/**
 * calculate breadCrumbs
 * @param {string} href - current href
 * @param {string} pathname - current pathname
 * @returns {Array} - breadcrumbs for currently viewed page
 */
__.getBreadCrumbs = function(href, pathname) {
	var pageIDArray = __.getPageID(href, pathname).split('/');
	var toReturn = [];
	var j = 0;
	var i;

	for (i=0; i<pageIDArray.length; i++) {
		if (pageIDArray[i] && pageIDArray[i] !== '') {
			if (j>2) {
				toReturn.push(pageIDArray[i]);
			}

			j++;
		}
	}

	return toReturn;
};

/**
 * get language from pathname
 * @param {string} pathname - the current pathname
 * @returns {string} the current language
 */
__.getLanguage = function(pathname) {
	var language;

	if (__.LocaleLanguage) {
		language = __.LocaleLanguage;
	}
	else {
		if (pathname.split('/')[1] === 'content') {
			language = pathname.split('/')[4];
		}
		else {
			language = pathname.split('/')[3];
		}
	}

	return __.removeTrailingHtmlString(language);
};

/**
 * get country code from pathname
 * @param {string} pathname - the current pathname
 * @returns {string} the current country ISO in uppercase
 */
__.getCountry = function(pathname) {
	if (__.LocaleCountry) {
		return __.LocaleCountry;
	}
	else {
		if (pathname.split('/')[1] === 'content') {
			return pathname.split('/')[2].toUpperCase();
		}
		else {
			return pathname.split('/')[1].toUpperCase();
		}
	}
};

/**
 * get the application name
 * @returns {string} application name
 */
__.getApplicationName = function() {
	var applicationName = __.getSiteName();

	if (!!applicationName && applicationName.indexOf('Audi ') === 0 && applicationName.length > 4) {
		applicationName = applicationName.substr(5);
	}

	return __.oDefaults.sStringApplicationNameAXSPrefix + applicationName;
};

/**
 * get the site name
 * @returns {string} site name
 */
__.getSiteName = function() {
	return SETUPS.get('SiteName');
};

/**
 * get the application version
 * @returns {string} application version
 */
__.getApplicationVersion = function() {
	var versionElemArr = document.getElementsByClassName('application-version');

	if (versionElemArr.length > 0) {
		return versionElemArr[0].getAttribute('data-version');
	}

	if (document.querySelectorAll('.nm-version-info dd') && document.querySelectorAll('.nm-version-info dd').length > 0) {
		return document.querySelectorAll('.nm-version-info dd')[1].innerHTML;
	}

	return undefined;
};

/**
 * get page type from domElement
 * @param {Element} breadCrumbArr - the dom element to retrieve page type from
 * @returns {string} the page type
 */
exports.getPageType = function(breadCrumbArr) {
	var pageType = 'standardPage';

	if (breadCrumbArr.length === 0) {
		pageType = 'homepage';
	}

	return pageType;
};

/**
 * get page object
 * @param {Object} dataObject - the the base tracking object
 * @returns {Object} the page object
 */
exports.getData = function(dataObject) {
	return new Promise(resolve => {
		var pageObject;
		var breadCrumbArr = __.getBreadCrumbs(window.location.href, window.location.pathname);

		__.LocaleLanguage = SETUPS.get('LocaleLanguage') ? SETUPS.get('LocaleLanguage') : null;
		__.LocaleCountry = SETUPS.get('LocaleCountry') ? SETUPS.get('LocaleCountry') : null;
		pageObject = {
			pageInfo: {
				pageID: __.getPageID(window.location.href, window.location.pathname),
				pageName: __.getPageName(document),
				destinationURL: __.getDestinationURL(),
				referringURL: __.getReferringURL(),
				sysEnv: __.getSysEnv(window.location.host),
				breadCrumbs: breadCrumbArr,
				language: __.getLanguage(window.location.pathname),
				market: __.getCountry(window.location.pathname)
			},
			category: {
				pageType: exports.getPageType(breadCrumbArr)
			},
			attributes: {
				applicationName: __.getApplicationName(),
				applicationVersion: __.getApplicationVersion(),
				implementer: 'BaD',
				site: __.getSiteName()

			}
		};
		dataObject.page = pageObject;
		resolve(dataObject);
	});
};

export {exports as pageObject};
